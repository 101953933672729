import { Button, Grid, Typography } from '@material-ui/core';
import { useCallback, useState, useEffect } from 'react';
import { useStyles } from './form-contrato-styles';
import { CircularLoading, makeUtilClasses } from 'views';
import React from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../../utils/form-default-props';
import { useForm, Controller } from 'react-hook-form';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { useCadastros, useSessaoAtual, useToastSaurus } from 'services/app';
import { ContratoFormModel } from 'model/app/forms/contrato/contrato-form-model';
import { useFormContratoPrimeiraFaseValidation } from './form-contrato-primeira-fase-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetPessoaSistema } from 'data/api/gestao/pessoa-sistema';
import { EnumSituacaoSistema } from 'model/enums/enum-situacao-sistema';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { isEmpty } from 'lodash';

interface Props extends DefaultFormProps<ContratoFormModel>{
  description?: string
  revendaId: string;
  setSistemaNome: (nome: string) => void;
}

export const FormContratoCadastroPrimeiraFase = React.forwardRef<
  DefaultFormRefs<ContratoFormModel>,
  Props
>((props: Props, refs) => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const [model, setModel] = useState({
    modeloForm: new ContratoFormModel(''),
  });
  const [sistemas, setSistemas] = useState<Array<KeyValueModel>>([])

  const { FormContratoPrimeiraFaseValidationYup } = useFormContratoPrimeiraFaseValidation()

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    setValue,
    getValues,
    clearErrors
  } = useForm<ContratoFormModel>({
    defaultValues: { ...model.modeloForm },
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(FormContratoPrimeiraFaseValidationYup)
  });

  const { getPessoaSistema, carregando: carregandoGetPessoaSistema } = useGetPessoaSistema()

  const {fecharCadastroContrato} = useCadastros()

  const { usuario, tipoUsuario } = useSessaoAtual()
  const { showToast } = useToastSaurus()

  const carregando = carregandoGetPessoaSistema

  const onSubmit = (form: ContratoFormModel) => {
    props.setSistemaNome(sistemas.find(sistema => sistema.Key === form.sistemaId)?.Value || '')
    props.onSubmit(form);
  };

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
      setModel({ modeloForm: new ContratoFormModel('') });
      reset();
    },
    fillForm: (model: ContratoFormModel) => {
      setModel({ modeloForm: model });
      reset({ ...model });
    },
  }));

  const pessoaSistemasWrapper = useCallback(async () => {
    const usuarioId = !isEmpty(props.revendaId) ? props.revendaId : tipoUsuario() === EnumTipoPessoas.Revendedor ? usuario?.Id : usuario?.RevendaId
    const res = await getPessoaSistema(usuarioId ?? '', 1, 'situacao=0')

    if(res.statusCode === 404){
      fecharCadastroContrato()
      return
    }

    if (res.erro) throw res.erro
    if (!res.resultado) return

    const sistemas = res.resultado?.data.list

    const sistemaMod = sistemas.filter(item => item.sistema.situacao === EnumSituacaoSistema.Ativo).map(item => {
      return new KeyValueModel(item.sistema.id, item.sistema.nome)
    })
    setSistemas([...sistemaMod])
  }, [fecharCadastroContrato, getPessoaSistema, props.revendaId, tipoUsuario, usuario?.Id, usuario?.RevendaId])

  useEffect(() => {
    pessoaSistemasWrapper()
      .catch(err => {
        showToast('error', err.message)
    })
  }, [showToast, pessoaSistemasWrapper, tipoUsuario])

  return (
    <>
      <div className={utilClasses.formContainer}>
        {(props.loading || carregando) && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            marginTop: 16
          }}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='h4'
                color="primary"
                align='center'
                style={!props.description ? {
                  marginBottom: 30
                } : undefined}
              >
                Selecione o Sistema
              </Typography>
            </Grid>
            {props.description && (
              <Grid item xs={12}>
                <Typography align='center' color='textSecondary' style={{
                  marginBottom: 30
                }}>{props.description}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name='sistemaId'
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    disabled={props.loading}
                    conteudo={sistemas}
                    fullWidth
                    variant="outlined"
                    label="Sistema"
                    allowSubmit
                    {...field}
                    value={getValues('sistemaId')}
                    onChange={(event) => {
                      if (event) {
                        const item = sistemas.filter(
                          (item) => item.Key === event.target.value,
                        )[0];
                        if (item) {
                          setValue('sistemaId', item.Key);
                          clearErrors('sistemaId')
                        }
                      }
                    }}
                    helperText={
                      touchedFields.sistemaId && errors.sistemaId
                        ? errors.sistemaId.message
                        : undefined
                    }
                    error={Boolean(errors.sistemaId && errors.sistemaId.message)}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});

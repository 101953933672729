import { KeyValueModel } from "model/api";
import { EnumSistemas } from "model/enums/enum-sistemas";


export const SistemaWhiteLabelMock: KeyValueModel[] = [
  new KeyValueModel(EnumSistemas.PDV_APP, 'PDV APP'),
  new KeyValueModel(EnumSistemas.RETAGUARDA_APP, 'Retaguarda APP'),
  new KeyValueModel(EnumSistemas.TOUCHONE, 'Touchone'),
];

export const SistemaWhitelabelArray = [
  // EnumSistemas.PDV_APP,
  // EnumSistemas.RETAGUARDA_APP,
  EnumSistemas.TOUCHONE
] as string[]

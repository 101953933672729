import { EnumTipoCobrancaRevenda } from "model/enums/enum-tipo-cobranca-revenda";
import { guidEmpty } from "utils/guid-empty";
import { PlanoModel } from "../plano/plano-model";
import { ContratoDadosModel } from "./contrato-dados-model";
import { ContratoModuloModel } from "./contrato-modulo-model";
import { EnumMotivoCancelamento } from "model/enums/enum-motivo-cancelamento";

export class ModulosContratadosModel {
    constructor(
        public quantidadeContratada: number = 0,
        public moduloId: string = guidEmpty()
    ) { }
}

export class ContratoModel {
    constructor(
        public id: string = guidEmpty(),
        public clienteId: string = guidEmpty(),
        public sistemaId: string = guidEmpty(),
        public ambienteId: string = guidEmpty(),
        public tabelaPrecoId: string = guidEmpty(),
        public planoId: string = guidEmpty(),
        public tabelaPreco: string = '',
        public melhorDia: number = 0,
        public dataExpiracao: string = '',
        public sistemaNome: string = '',
        public ambienteNome: string = '',
        public valorVigente: number = 0,
        public valorCalculado: number = 0,
        public valorCusto: number = 0,
        public dataFaturaMaisAntiga: string = '',
        public dataUltimaFatura: string = '',
        public dataAdesao: string = '',
        public valorUltimaFatura: number = 0,
        public ativo: boolean = true,
        public dataCancelamento: string = '',
        public usuarioCancelamento: string = '',
        public motivoCancelamento: string = '',
        public indCancelamento: EnumMotivoCancelamento = EnumMotivoCancelamento.TrocaParaSaurus,
        public dominio: string = '',
        public plano: PlanoModel = new PlanoModel(),
        public modulos: ContratoModuloModel[] = [],
        public formaPagamentoId: string = guidEmpty(),
        public revendaId: string = guidEmpty(),
        public tipoCobrancaRevenda: EnumTipoCobrancaRevenda = EnumTipoCobrancaRevenda.ClienteFinal,
        public formaPagamentoNome: string = '',
        public dadosTela: ContratoDadosModel = new ContratoDadosModel(),
    ) { }
}
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Box, Grid, Button, Typography, Divider } from '@material-ui/core';
import {
    DefaultFormProps,
    DefaultFormRefs,
} from 'views/components/form/utils/form-default-props';
import { useThemeQueries, makeUtilClasses } from 'views';
import { Controller, useForm } from 'react-hook-form';
import { picker } from 'utils/picker';
import { useStyles } from './form-whitelabel-styles'
import { PessoaWhitelabelModel } from 'model/api/gestao/pessoa/pessoa-whitelabel-model';
import { ImagemInput, InputColor, TextFieldSaurus } from 'views/components/controles/inputs';
import ImagemPadrao from 'assets/img/usuario-sem-imagem.png'
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { KeyValueModel } from 'model';
import { EnumSistemas } from 'model/enums/enum-sistemas';
import { useFormWhitelabelValidation } from './form-whitelabel-validation';
import { yupResolver } from '@hookform/resolvers/yup';

interface FormWhitelabelProps extends DefaultFormProps<PessoaWhitelabelModel> {
    sistema: EnumSistemas;
    disableFields?: boolean;
}

export const FormWhitelabel = forwardRef<
    DefaultFormRefs<PessoaWhitelabelModel>,
    FormWhitelabelProps
>(
    (
        { loading, onSubmit, model, sistema, ...props }: FormWhitelabelProps,
        ref,
    ) => {
        const { isMobile } = useThemeQueries();
        const utilClasses = makeUtilClasses();
        const { FormWhitelabelValidationYup } = useFormWhitelabelValidation(sistema)
        const classes = useStyles();
        const refInputNome = useRef<HTMLInputElement>(null);
        const [modelForm, setModelForm] = useState<PessoaWhitelabelModel>(
            new PessoaWhitelabelModel(),
        );

        const {
            handleSubmit,
            reset,
            getValues,
            setValue,
            control,
            formState: { errors }
        } = useForm<PessoaWhitelabelModel>({
            defaultValues: { ...modelForm },
            criteriaMode: 'all',
            mode: 'onBlur' && 'onChange',
            resolver: yupResolver(FormWhitelabelValidationYup)
        });

        const submitEnviar = (values: PessoaWhitelabelModel) => {
            const whitelabel = picker<PessoaWhitelabelModel>(values, modelForm);
            onSubmit(whitelabel, modelForm);
        };

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleSubmit(submitEnviar)();
            },
            resetForm: () => {
                reset();
                if (!isMobile) refInputNome.current?.focus();
            },
            fillForm: (model: PessoaWhitelabelModel) => {
                setModelForm({ ...model });
                reset({ ...model });
                if (!isMobile) refInputNome.current?.focus();
            },
        }));

        const imagemBase64 = useCallback((value: string) => {
            try {
                return window.atob(value)
            } catch (e: any) {
                return value
            }
        }, [])

        return (
            <>
                <Box className={classes.root}>
                    <div>
                        <form
                            onSubmit={handleSubmit(submitEnviar)}
                            className={loading ? utilClasses.controlLoading : ''}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name='nome'
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Nome'
                                                disabled={props.disableFields}
                                                placeholder='Nome do Whitelabel'
                                                autoComplete='new-password'
                                                {...field}
                                                error={Boolean(errors.nome)}
                                                helperText={
                                                    errors.nome && errors.nome.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                {sistema !== EnumSistemas.RETAGUARDA_APP && <>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' color='primary'>Imagens</Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.center}>
                                        <Controller
                                            name='urlImagemPrimaria'
                                            control={control}
                                            render={({ field }) => (
                                                <ImagemInput
                                                    loadErrorImage={ImagemPadrao}
                                                    accept='image/*'
                                                    disabled={props.disableFields}
                                                    imageRadius="50%"
                                                    helperText={errors.urlImagemPrimaria ? errors.urlImagemPrimaria.message : 'Imagem Primária'}
                                                    width={isMobile ? '150px' : '180px'}
                                                    height={isMobile ? '150px' : '180px'}
                                                    error={Boolean(
                                                        errors.urlImagemPrimaria && errors.urlImagemPrimaria.message,
                                                    )}
                                                    {...field}
                                                    value={imagemBase64(getValues('urlImagemPrimaria'))}
                                                    onChange={({ base64 }: any) => {
                                                        setValue('urlImagemPrimaria', base64);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {(sistema === EnumSistemas.PDV_APP || sistema === EnumSistemas.MOBILE) && <Grid item xs={12} md={6} className={classes.center}>
                                        <Controller
                                            name='urlImagemSecundaria'
                                            control={control}
                                            render={({ field }) => (
                                                <ImagemInput
                                                    loadErrorImage={ImagemPadrao}
                                                    accept='image/*'
                                                    disabled={props.disableFields}
                                                    helperText={errors.urlImagemSecundaria ? errors.urlImagemSecundaria.message : 'Imagem Secundária'}
                                                    imageRadius="50%"
                                                    width={isMobile ? '150px' : '180px'}
                                                    height={isMobile ? '150px' : '180px'}
                                                    error={Boolean(
                                                        errors.urlImagemSecundaria && errors.urlImagemSecundaria.message,
                                                    )}
                                                    {...field}
                                                    value={imagemBase64(getValues('urlImagemSecundaria'))}
                                                    onChange={({ base64 }: any) => {
                                                        setValue('urlImagemSecundaria', base64);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>}
                                    <Grid item xs={12} md={6} className={classes.center}>
                                        <Controller
                                            name='urlIcone'
                                            control={control}
                                            render={({ field }) => (
                                                <ImagemInput
                                                    loadErrorImage={ImagemPadrao}
                                                    accept='image/*'
                                                    disabled={props.disableFields}
                                                    helperText={errors.urlIcone ? errors.urlIcone.message : 'Ícone'}
                                                    imageRadius="50%"
                                                    width={isMobile ? '80px' : '100px'}
                                                    height={isMobile ? '80px' : '100px'}
                                                    error={Boolean(
                                                        errors.urlIcone && errors.urlIcone.message,
                                                    )}
                                                    {...field}
                                                    value={imagemBase64(getValues('urlIcone'))}
                                                    onChange={({ base64 }: any) => {
                                                        setValue('urlIcone', base64);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>}
                                {sistema !== EnumSistemas.RETAGUARDA_APP && <>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' color='primary'>Cores</Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name='corPrincipal'
                                            control={control}
                                            render={({ field }) => (
                                                <InputColor
                                                    labelTxt='Cor Primária'
                                                    disabled={props.disableFields}
                                                    {...field}
                                                    valor={getValues('corPrincipal')}
                                                    error={Boolean(errors.corPrincipal)}
                                                    helperText={Boolean(errors.corPrincipal) ? errors.corPrincipal?.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            name='corSecundaria'
                                            control={control}
                                            render={({ field }) => (
                                                <InputColor
                                                    labelTxt='Cor Secundária'
                                                    disabled={props.disableFields}
                                                    {...field}
                                                    valor={getValues('corSecundaria')}
                                                    error={Boolean(errors.corSecundaria)}
                                                    helperText={Boolean(errors.corSecundaria) ? errors.corSecundaria?.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {sistema === EnumSistemas.PDV_APP && <Grid item xs={12} md={6}>
                                        <Controller
                                            name='corTerciaria'
                                            control={control}
                                            render={({ field }) => (
                                                <InputColor
                                                    labelTxt='Cor Terciaria'
                                                    disabled={props.disableFields}
                                                    {...field}
                                                    valor={getValues('corTerciaria')}
                                                    error={Boolean(errors.corTerciaria)}
                                                    helperText={Boolean(errors.corTerciaria) ? errors.corTerciaria?.message : undefined}
                                                />
                                            )}
                                        />
                                    </Grid>}
                                </>}
                                <Grid item xs={12}>
                                    <Typography variant='h6' color='primary'>Configuração de E-mail</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='emailNome'
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Nome E-mail'
                                                disabled={props.disableFields}
                                                autoComplete='new-password'
                                                {...field}
                                                error={Boolean(errors.emailNome)}
                                                helperText={
                                                    errors.emailNome && errors.emailNome.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='emailAddress'
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Endereço de E-mail'
                                                disabled={props.disableFields}
                                                tipo='EMAIL'
                                                autoComplete='new-password'
                                                {...field}
                                                error={Boolean(errors.emailAddress)}
                                                helperText={
                                                    errors.emailAddress && errors.emailAddress.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='emailPassword'
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Senha do E-mail'
                                                disabled={props.disableFields}
                                                tipo='PASSWORD'
                                                autoComplete='new-password'
                                                showPasswordVisibilityButton
                                                {...field}
                                                error={Boolean(errors.emailPassword)}
                                                helperText={
                                                    errors.emailPassword && errors.emailPassword.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='emailSmtp'
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='SMTP'
                                                disabled={props.disableFields}
                                                tipo='URL'
                                                {...field}
                                                error={Boolean(errors.emailSmtp)}
                                                helperText={
                                                    errors.emailSmtp && errors.emailSmtp.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='emailPort'
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldSaurus
                                                label='Porta'
                                                disabled={props.disableFields}
                                                tipo='NUMERO'
                                                {...field}
                                                error={Boolean(errors.emailPort)}
                                                helperText={
                                                    errors.emailPort && errors.emailPort.message
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name='emailSSL'
                                        control={control}
                                        render={({ field }) => (
                                            <SelectSaurus
                                                conteudo={[
                                                    new KeyValueModel(true, 'Sim'),
                                                    new KeyValueModel(false, 'Não')
                                                ]}
                                                disabled={props.disableFields}
                                                label='SSL'
                                                {...field}
                                                error={Boolean(errors.emailSSL)}
                                                helperText={
                                                    errors.emailSSL && errors.emailSSL.message
                                                }
                                                onChange={(e) => setValue('emailSSL', Boolean(e.target.value))}
                                            />
                                        )}
                                    />
                                </Grid>
                                {sistema === EnumSistemas.RETAGUARDA_APP && <>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' color='primary'>URL's Externas</Typography>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='urlCssProprio'
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='URL CSS Próprio'
                                                    placeholder='Árquivo CSS Externo'
                                                    disabled={props.disableFields}
                                                    tipo='URL'
                                                    {...field}
                                                    error={Boolean(errors.urlCssProprio)}
                                                    helperText={
                                                        errors.urlCssProprio && errors.urlCssProprio.message
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='urlJsProprio'
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='URL JavaScript Próprio'
                                                    placeholder='Árquivo JavaScript Externo'
                                                    disabled={props.disableFields}
                                                    tipo='URL'
                                                    {...field}
                                                    error={Boolean(errors.urlJsProprio)}
                                                    helperText={
                                                        errors.urlJsProprio && errors.urlJsProprio.message
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name='urlHtmlProprio'
                                            control={control}
                                            render={({ field }) => (
                                                <TextFieldSaurus
                                                    label='URL HTML Próprio'
                                                    placeholder='Árquivo HTML Externo'
                                                    disabled={props.disableFields}
                                                    tipo='URL'
                                                    {...field}
                                                    error={Boolean(errors.urlHtmlProprio)}
                                                    helperText={
                                                        errors.urlHtmlProprio && errors.urlHtmlProprio.message
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>}
                            </Grid>
                            <Button style={{ display: 'none' }} type="submit"></Button>
                        </form>
                    </div>
                </Box>
            </>
        );
    });

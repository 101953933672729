import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { useStyles } from './form-contrato-styles';
import { CircularLoading, makeUtilClasses, useThemeQueries } from 'views';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../../utils/form-default-props';
import { Controller, useForm } from 'react-hook-form';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { isEmpty } from 'lodash';
import { ContratoModuloModel } from 'model/api/gestao/contrato/contrato-modulo-model';
import { ContratoFormEdicaoModel } from 'model/app/forms/contrato/contrato-form-model';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { EnumTipoCalculo, KeyValueModel } from 'model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import { calcPercent } from 'utils/calc-percent';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { guidEmpty } from 'utils/guid-empty';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormContratoEdicaoValidation } from './form-contrato-validation';
import { PrecoModulos, TabelasSistema } from 'model/api/gestao/sistemas/sistema-tabela-precos-model';
import { toDateString, toDateStringApi } from 'utils/to-date';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { ModuloModel } from 'model/api/gestao/sistemas/modulo-model';
import { EnumTipoCobrancaRevenda } from 'model/enums/enum-tipo-cobranca-revenda';
import { useConfirm } from 'material-ui-confirm';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { toCurrency } from 'utils/toCurrency';
import { PlanoModel } from 'model/api/gestao/plano/plano-model';
import { ValoresState } from '../contrato-cadastro/form-contrato-cadastro';
import { FilialIcon } from 'views/components/icons/filial-icon';
import CardModulosContratoEdicao from 'views/components/cards/card-modulos-contrato-edicao/card-modulos-contrato-edicao';
import { EnumMotivoCancelamento } from 'model/enums/enum-motivo-cancelamento';
import { MotivoCancelamentoMock } from 'data/mocks/motivo-cancelamento-mock';

interface Props extends DefaultFormProps<ContratoFormEdicaoModel> {
  pessoaId: string;
  setDisable: React.Dispatch<React.SetStateAction<boolean[]>>;
  setOpenDialog: () => void;
  disable: Array<boolean>;
  sistemaId: string;
  tipoCobranca: EnumTipoCobrancaRevenda;
  dataCancelamento?: string;
  motivoCancelamento?: string;
  indCancelamento?: EnumMotivoCancelamento;
  status: boolean;
  showFiliais: boolean;
}

interface IdsModel {
  moduloId: string;
  id: string;
  quantidadeContratada: number;
}

export class ValoresFinais {
  constructor(
    public vCalculado: number = 0,
    public vCusto: number = 0,
    public vContrato: number = 0,
    public vCustoOriginal: number = 0,
  ) {

  }
}

export const FormContratoEditar = React.forwardRef<
  DefaultFormRefs<ContratoFormEdicaoModel>,
  Props
>((props: Props, refs) => {
  const utilClasses = makeUtilClasses();
  const classes = useStyles();
  const { showToast } = useToastSaurus();
  const { tipoUsuario } = useSessaoAtual()
  const confirm = useConfirm()
  const [planosMock, setPlanosMock] = useState<KeyValueModel[]>([]);
  const [precosModulosCard, setPrecosModulosCard] = useState<
    ContratoModuloModel[]
  >([]);
  const [model, setModel] = useState<ContratoFormEdicaoModel>(
    new ContratoFormEdicaoModel(),
  );
  const [recalcularContrato, setRecalcularContrato] = useState<boolean>(false)

  const { isMobile, theme } = useThemeQueries();
  const { usuario } = useSessaoAtual()

  const isTipoRevenda = (usuario?.TipoCobrancaRevenda === EnumTipoCobrancaRevenda.Revenda || usuario?.TipoCobrancaRevenda === EnumTipoCobrancaRevenda.RevendaPagas) ||
    (props.tipoCobranca === EnumTipoCobrancaRevenda.Revenda || props.tipoCobranca === EnumTipoCobrancaRevenda.RevendaPagas)

  const podeVerCusto = useMemo(() => (tipoUsuario() !== EnumTipoPessoas.FuncionarioFinanceiro && tipoUsuario() !== EnumTipoPessoas.Representante), [tipoUsuario])


  const [ambienteMock, setAmbienteMock] = useState<KeyValueModel[]>([])


  const [planoId, setPlanoId] = useState<string | null>('');
  const [planos, setPlanos] = useState<PlanoModel[]>([])
  const { FormContratoEdicaoValidationYup } = useFormContratoEdicaoValidation({ isRetaguarda: props.sistemaId === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9', isTipoRevenda, ambiente: ambienteMock.length > 0 });

  const [tabelasPreco, setTabelasPreco] = useState<TabelasSistema[]>([]);
  const [tabelaPreco, setTabelaPreco] = useState<TabelasSistema>(
    new TabelasSistema(),
  );
  const tabelaPrecoOriginal = useRef<TabelasSistema>(new TabelasSistema());
  const idsOriginais = useRef<IdsModel[]>([])

  const [ambiente, setAmbiente] = useState<string>('')
  const [pagamentoMock, setPagamentoMock] = useState<KeyValueModel[]>([])

  const [custoVigente, setCustoVigente] = useState<number>(0);
  const [valoresState, setValoresState] = useState<ValoresState>(new ValoresState())



  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<ContratoFormEdicaoModel>({
    defaultValues: { ...model },
    resolver: yupResolver(FormContratoEdicaoValidationYup),
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const carregando = props.loading;

  const isRetaguarda = getValues('sistemaId') === 'b2a22b5d-b681-43e1-b110-44bc2f8809b9'

  const onSubmit = useCallback((form: ContratoFormEdicaoModel) => {
    form.modulos = [...precosModulosCard];
    form.valorContrato = toDecimal(form.valorContrato, 2)
    form.valorCalculado = toDecimal(form.valorCalculado, 2)

    if (form.modulos.find(modulo => (modulo.quantidadeContratada > modulo.quantidadeMaxima ||
      modulo.quantidadeContratada < modulo.quantidadeMinima) && (modulo.quantidadeMinima > -1 && modulo.quantidadeMaxima > -1))) {
      showToast('error', 'Módulos com quantidade incorretas.')
      return
    }

    if (form.dataExpiracao) {
      form.dataExpiracao = toDateStringApi(new Date(form.dataExpiracao))
    }

    if (isTipoRevenda) {
      form.formaPagamentoId = null
    }

    form.melhorDia = 5
    props.onSubmit(form, model);
  }, [isTipoRevenda, model, precosModulosCard, props, showToast])

  const validarModulosDependentes = useCallback(() => {
    const modulosDependentes = precosModulosCard.filter(modulo => modulo.tipoCalculo === EnumTipoCalculo.Modulo && modulo.tipoControle !== EnumTipoControleModulo.Filial && modulo.quantidadeContratada > 0)
    let modulosZerados: string[] = []

    modulosDependentes.forEach(modulo => {
      const moduloPai = precosModulosCard.find(mod => mod.codigo.toString() === modulo.codModuloBaseCalculo)
      if (moduloPai!.quantidadeContratada < 1) {
        if (modulosZerados.includes(moduloPai!.nome)) return
        modulosZerados = [...modulosZerados, moduloPai!.nome]
      }
    })
    if (modulosZerados.length > 0) {
      showToast('error', `Você possuí módulos selecionados que dependem de módulos não selecionados.
      Módulos Dependentes: ${modulosDependentes.reduce<string>((prev, curr) => isEmpty(prev) ? curr.nome : `${prev}, ${curr.nome}`, '')}
      Módulos não selecionados: ${modulosZerados.reduce((prev, curr) => `${prev}, ${curr}`)}`, 8000)
    }

    return modulosZerados.length > 0
  }, [precosModulosCard, showToast])

  const confirmSubmit = useCallback((form: ContratoFormEdicaoModel) => {
    if (validarModulosDependentes()) return
    confirm({
      title: 'Alterar Contrato',
      description: (
        <Box display='flex' flexDirection='column' gridGap={theme.spacing(2)}>
          {(toDecimal(form.valorContrato) < form.valorCusto) && <Typography color='error'>ATENÇÃO: O valor do seu contrato é inferior ao valor de custo!</Typography>}
          <Typography>Deseja salvar este contrato? Valor do {isTipoRevenda ? 'custo' : 'contrato'}: <b>{toCurrency(toDecimal(isTipoRevenda ? form.valorCusto : form.valorContrato))}</b></Typography>
        </Box>
      ),
      cancellationText: 'Cancelar',
      confirmationText: 'Salvar'
    }).then(() => onSubmit(form))
  }, [confirm, isTipoRevenda, onSubmit, theme, validarModulosDependentes])

  React.useImperativeHandle(refs, () => ({
    submitForm: () => {
      handleSubmit(confirmSubmit)();
    },
    resetForm: () => {
      reset({ ...model });
      setPrecosModulosCard(model.modulos);
    },
    fillForm: (model: ContratoFormEdicaoModel) => {
      if (model.dataExpiracao) {
        model.dataExpiracao = model.dataExpiracao.split('T')[0]
      }
      setModel({ ...model });
      setPlanoId(model.planoId);
      setPrecosModulosCard(model.modulos);
      setAmbiente(model.ambienteId)
      const precosModulosOriginais = model.modulos.map(modulo => {
        const mod = new ModuloModel(modulo.id, modulo.nome, modulo.tipoCalculo,
          modulo.codModuloBaseCalculo, modulo.codigo, '', modulo.quantidadeMinima,
          modulo.quantidadeMaxima, modulo.quantidadePadrao, -1 as EnumTipoControleModulo, '')
        return new PrecoModulos(modulo.id, modulo.valorOriginal, modulo.valorCustoOriginal,
          modulo.percAdicional, modulo.valorCusto, modulo.percAdicionalCusto, mod)
      })
      tabelaPrecoOriginal.current = new TabelasSistema(model.tabelaPrecoId, model.tabelaPreco, precosModulosOriginais)
      idsOriginais.current = model.modulos.map(mod => ({
        moduloId: mod.moduloId,
        id: mod.id,
        quantidadeContratada: mod.quantidadeContratada
      }))

      //setando valores que vem do dadosTela

      const ambientesRevenda = model.dadosTela.ambientes.filter(ambiente => ambiente.revendaId === model.revendaId)
      const keyValueRevenda = ambientesRevenda.length > 0 ? [new KeyValueModel(-5, 'Meus Ambientes'), ...ambientesRevenda.map(ambiente => new KeyValueModel(ambiente.id, ambiente.nome))] : []

      const ambientesGlobais = model.dadosTela.ambientes.filter(ambiente => ambiente.revendaId !== model.revendaId)
      const keyValueGlobais = ambientesGlobais.length > 0 ? [new KeyValueModel(-5, 'Ambientes Globais'), ...ambientesGlobais.map(ambiente => new KeyValueModel(ambiente.id, ambiente.nome))] : []

      setAmbienteMock([...keyValueRevenda, ...keyValueGlobais])
      setPagamentoMock(model.dadosTela.pagamentos.map(pagamento => new KeyValueModel(pagamento.id, pagamento.nome)))
      setPlanosMock(model.dadosTela.planos.map(plano => new KeyValueModel(plano.id, plano.nome)))
      setPlanos(model.dadosTela.planos)
      setTabelaPreco(!isEmpty(model.dadosTela.precos.tabelas) ? model.dadosTela.precos.tabelas.find(tabela => tabela.tabelaPrecoId === model.tabelaPrecoId) || model.dadosTela.precos.tabelas[0] : new TabelasSistema())
      setTabelasPreco(model.dadosTela.precos.tabelas)
      setValue('tabelaPrecoId', model.tabelaPrecoId)
      setCustoVigente(model.valorCusto)

      reset({ ...model });

    },
  }));

  // const quantidadeDias = () => {
  //   let dias: Array<number> = [5, 20, 25, 30];
  //   return dias.map((item) => new KeyValueModel(item, item === 30 ? `${item} (Desabilitado)` : item.toString()));
  // };

  const selecionarPlano = useCallback(
    (item: KeyValueModel) => {
      const plano = planos.find(plano => plano.id === item.Key) || new PlanoModel()


      setPrecosModulosCard((prev) => {
        const ret = plano.modulos;
        const modulosNaoPlanos = prev
          .filter(
            (item) =>
              !ret.map((fill) => fill.moduloId).includes(item.moduloId),
          )
          .map((item) => ({
            ...item,
            editavel: true,
          }));
        const temPlanos = plano.modulos.map((item) => {
          const valoresPrev = prev.filter(
            (pr) => pr.moduloId === item.moduloId,
          )[0];

          return {
            ...valoresPrev,
            quantidadeContratada: item!.quantidadeContratada,
            editavel: false,
          };
        });
        return [...modulosNaoPlanos, ...temPlanos];
      });
      setValue('planoId', item.Key);
      setPlanoId(item.Key);
    },
    [planos, setValue],
  );

  const valoresFinais = useMemo(() => {
    return (
      precosModulosCard.map((item) => {
        let quantMult = item.quantidadeContratada;
        let quantMult2 = item.quantidadeContratada;
        let quantMult3 = item.quantidadeContratada;
        let valorFinal = item.valorOriginal;
        let valorCusto = item.valorCusto;
        let valorCustoOriginal = item.valorCustoOriginal;

        if (item.quantidadeContratada > 1) {
          quantMult =
            toDecimal((item.quantidadeContratada - 1) *
              calcPercent(item.valorOriginal, item.percAdicional), 6);
          quantMult2 =
            toDecimal((item.quantidadeContratada - 1) *
              calcPercent(item.valorCusto, item.percAdicionalCusto), 6);
          quantMult3 =
            toDecimal((item.quantidadeContratada - 1) *
              calcPercent(item.valorCustoOriginal, item.percAdicionalCusto), 6);
        }

        if (item.quantidadeContratada === 0) {
          valorFinal = 0;
          valorCusto = 0;
          valorCustoOriginal = 0;
        } else if (item.quantidadeContratada < 2) {
          valorFinal = item.valorOriginal;
          valorCusto = item.valorCusto;
          valorCustoOriginal = item.valorCustoOriginal;
        } else {
          valorFinal = valorFinal + quantMult;
          valorCusto = valorCusto + quantMult2;
          valorCustoOriginal = valorCustoOriginal + quantMult3;
        }

        if (item.tipoCalculo === EnumTipoCalculo.Modulo && item.tipoControle === EnumTipoControleModulo.Filial) {
          item.codModuloBaseCalculo = item.codModuloBaseCalculo.toString()
          const codsBase = item.codModuloBaseCalculo.includes(',') ? item.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : item.codModuloBaseCalculo.split('-').map(cod => Number(cod))
          const moduloBase = precosModulosCard.filter(mod => codsBase.includes(mod.codigo))

          const valorBase = toDecimal(moduloBase.reduce((prev, curr) => {
            if (curr.quantidadeContratada === 0) return prev
            if (curr.quantidadeContratada === 1) {
              return curr.valorOriginal + prev
            }
            return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorOriginal, curr.percAdicional) + curr.valorOriginal + prev, 6)
          }, 0))


          const valorBaseCusto = moduloBase.reduce((prev, curr) => {
            if (curr.quantidadeContratada === 0) return prev
            if (curr.quantidadeContratada === 1) {
              return curr.valorCusto + prev
            }
            return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorCusto, curr.percAdicionalCusto) + curr.valorCusto + prev, 6)
          }, 0)

          const valorBaseCustoOriginal = moduloBase.reduce((prev, curr) => {
            if (curr.quantidadeContratada === 0) return prev
            if (curr.quantidadeContratada === 1) {
              return curr.valorCustoOriginal + prev
            }
            return toDecimal((curr.quantidadeContratada - 1) * calcPercent(curr.valorCustoOriginal, curr.percAdicionalCusto) + curr.valorCustoOriginal + prev, 6)
          }, 0)

          valorFinal = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBase, item.percAdicional), 6) : valorFinal
          valorCusto = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBaseCusto, item.percAdicionalCusto), 6) : valorCusto
          valorCustoOriginal = moduloBase.length > 0 && item.quantidadeContratada > 0 ? toDecimal((item.quantidadeContratada) * calcPercent(valorBaseCustoOriginal, item.percAdicionalCusto), 6) : valorCustoOriginal

        } else if (item.tipoCalculo === EnumTipoCalculo.Modulo) {
          const codBase = Number(item.codModuloBaseCalculo)
          const moduloBase = precosModulosCard.find(mod => mod.codigo === codBase)

          valorFinal = moduloBase ? valorFinal * (moduloBase?.quantidadeContratada || 1) : valorFinal
          valorCusto = moduloBase ? valorCusto * (moduloBase?.quantidadeContratada || 1) : valorCusto
          valorCustoOriginal = moduloBase ? valorCustoOriginal * (moduloBase.quantidadeContratada || 1) : valorCustoOriginal
        }

        return {
          valorFinal: valorFinal,
          valorCusto: valorCusto,
          valorCustoOriginal: valorCustoOriginal
        };
      })
    )
  }, [precosModulosCard]);

  const alterarQtdModulo = useCallback(
    (model: ContratoModuloModel) => {
      let modulo = [...precosModulosCard];
      const ret = modulo.find((item) => item.moduloId === model.moduloId);
      const index = modulo.indexOf(ret || new ContratoModuloModel());
      modulo[index] = model;
      setRecalcularContrato(true)
      setPrecosModulosCard(modulo);
    },
    [precosModulosCard],
  );

  const alterarQtd = useCallback(
    (model: ContratoModuloModel, qtde: number) => {
      const newState = Object.assign({}, model);

      newState.quantidadeContratada = qtde;
      alterarQtdModulo(newState);
    },
    [alterarQtdModulo],
  );

  const setValores = useCallback(
    (event: any) => {
      if (toDecimal(event.target.value, 4) < 0 || event.target.value.includes('-'))
        return;
      setValue(event.target.name, event.target.value);

      const valorDesconto = toDecimal(
        event.target.name === 'valorDesconto'
          ? event.target.value
          : getValues('valorDesconto'), 4
      );


      const valorContrato = toDecimal(
        event.target.name === 'valorContrato'
          ? event.target.value
          : getValues('valorContrato'), 4
      );

      if (event.target.name === 'valorDesconto') {
        setValue(
          'valorContrato',
          toDecimal(valoresFinais.reduce((a: any, b: any) => a + b.valorFinal, 0) -
            valorDesconto, 4)
        );
        return;
      }

      if (event.target.name === 'valorContrato') {
        setValue(
          'valorDesconto',
          toDecimal(toDecimal(getValues('valorCalculado'), 4) - valorContrato, 4) < 0 ? (
            0
          ) : (
            toDecimal(toDecimal(getValues('valorCalculado'), 4) - valorContrato, 4)
          )
        );
        setValoresState(prev => ({
          ...prev,
          valorContrato: valorContrato
        }))
      }
    },
    [getValues, setValue, valoresFinais],
  );

  const validateFields = useCallback(() => {
    const valorContrato = toDecimal(getValues('valorContrato'), 4);
    const valorTabelado = toDecimal(getValues('valorCalculado'), 4)
    if (
      valorContrato < valorTabelado &&
      !props.disable[1] && !podeVerCusto
    ) {
      setError('valorContrato', {
        type: 'error',
      });
      props.setDisable((prev) => [prev[0], true, prev[2]]);
    } else if (
      (valorContrato >= valorTabelado) &&
      props.disable[1]
    ) {
      clearErrors('valorContrato');
      props.setDisable((prev) => [prev[0], false, prev[2]]);
    }
  }, [clearErrors, getValues, podeVerCusto, props, setError]);

  useEffect(() => {
    // try catch pra retirar o erro que não estava entendendo o que estava acontecendo
    try {
      const { vCalculado, vContrato, vCusto, vCustoOriginal } = valoresFinais.reduce<ValoresFinais>((prev: ValoresFinais, curr: any) => {
        const newRetorno = { ...prev }

        newRetorno.vCalculado += curr.valorFinal
        newRetorno.vCusto += curr.valorCusto
        newRetorno.vContrato += curr.valorFinal
        newRetorno.vCustoOriginal += curr.valorCustoOriginal

        return newRetorno
      }, new ValoresFinais())

      setValue(
        'valorCalculado',
        vCalculado,
      );

      setValue(
        'valorCusto',
        vCusto,
      );

      if (recalcularContrato) {
        setValue(
          'valorContrato',
          vContrato,
        );
      } else {

      }

      setValoresState(prev => ({
        ...prev,
        valorCalculado: vCalculado,
        valorContrato: recalcularContrato ? vContrato : model.valorContrato,
        valorCusto: vCusto,
        valorCustoOriginal: vCustoOriginal
      }))

      validateFields()
    } catch { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [precosModulosCard]);

  useEffect(() => {
    if (!isEmpty(tabelaPreco) && !isEmpty(tabelaPreco.precoModulos)) {

      setPrecosModulosCard((prev) => {
        return tabelaPreco.precoModulos.map(
          (item, index) => {
            let isValueEqual = true;
            let prevValue: number | undefined = 0;
            if (tabelaPrecoOriginal.current.tabelaPrecoId === tabelaPreco.tabelaPrecoId) {
              prevValue = tabelaPrecoOriginal.current.precoModulos.find(x => x.modulo.codigo === item.modulo.codigo)?.valor;
              if (prevValue !== item.valor) {
                isValueEqual = false;
              }
            }
            return new ContratoModuloModel(
              idsOriginais.current.find(x => x.moduloId === item.modulo.id)?.id,
              item.modulo.codigo,
              item.modulo.nome,
              item.valor,
              item.valorCustoOriginal,
              idsOriginais.current.find(x => x.moduloId === item.modulo.id)?.quantidadeContratada,
              item.valor,
              item.percAdicional,
              item.modulo.id,
              model.id,
              item.modulo.quantidadeMaxima,
              item.modulo.quantidadeMinima,
              item.modulo.quantidadePadrao,
              item.valorCusto,
              item.percAdicionalCusto,
              item.modulo.codModuloBaseCalculo,
              item.modulo.tipoCalculo,
              item.modulo.tipoControle,
              !isValueEqual,
              prevValue
            )
          }
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, model.id, setValue, showToast, tabelaPreco]);

  const removePlano = useCallback(() => {
    setValue('planoId', null);
    setPlanoId('');
  }, [setValue]);

  const valorLucro = useCallback((valor: number, valorCusto: number) => {
    const vLucro = toDecimal(valor - valorCusto);
    const percLucro = toDecimal(((vLucro / valorCusto) * 100))
    return `${toCurrency(vLucro)} (%${isNaN(percLucro) ? 0 : percLucro === Infinity ? '∞' : toDecimalString(percLucro)})`
  }, [])

  //Memos para validar exibição dos campos (decidi criar um memo para organizar e validar as grids dos campos, porque os ternários estavam poluindo mt o código)

  const camposSuperiores = useMemo(() => {

    let campos = <></>;

    const gridDominio = !isTipoRevenda || ambienteMock.length > 0 ? 6 : 12
    const gridFormaPagamento = isRetaguarda || ambienteMock.length > 0 ? 6 : 12
    const gridAmbiente = !isTipoRevenda && isRetaguarda ? 12 : 6

    if (isRetaguarda) {
      campos = <>
        {campos}
        <Grid item lg={gridDominio} xs={12}>
          <TextFieldSaurus
            value={getValues('dominio')}
            readOnly
            size='small'
            label='Domínio'
          />
        </Grid>

      </>
    }

    if (!isTipoRevenda) {
      campos = <>
        {campos}
        <Grid item lg={gridFormaPagamento} xs={12}>
          <Controller
            name="formaPagamentoId"
            control={control}
            render={({ field }) => (
              <SelectSaurus
                label="Forma de Pagamento"
                conteudo={pagamentoMock}
                size='small'
                helperText={
                  errors.formaPagamentoId
                    ? errors.formaPagamentoId.message
                    : undefined
                }
                error={Boolean(
                  errors.formaPagamentoId && errors.formaPagamentoId.message,
                )}
                {...field}
                onChange={(ev) => {
                  const item = pagamentoMock.filter(
                    (item) => item.Key === ev.target.value,
                  );

                  setValue('formaPagamentoId', item[0].Key);
                }}
              />
            )}
          />
        </Grid>
      </>
    }

    if (ambienteMock.length > 0) {
      campos = <>
        {campos}
        <Grid item lg={gridAmbiente} xs={12}>
          <Controller
            name="ambienteId"
            control={control}
            render={({ field }) => (
              <SelectSaurus
                conteudo={ambienteMock}
                disabled={isEmpty(ambienteMock)}
                fullWidth
                variant="outlined"
                label="Ambiente"
                size='small'
                {...field}
                value={ambiente}
                onChange={(event) => {
                  if (event) {
                    const item = ambienteMock.filter(
                      (item) =>
                        item.Key === event.target.value,
                    )[0];
                    if (item) {
                      setAmbiente(item.Key)
                      setValue('ambienteId', item.Key);
                      return
                    }
                    setAmbiente('')
                    setValue('ambienteId', '')
                  }
                }}
                helperText={
                  errors.ambienteId
                    ? errors.ambienteId.message
                    : undefined
                }
                error={Boolean(
                  errors.ambienteId && errors.ambienteId.message,
                )}
              />
            )}
          />
        </Grid>
      </>
    }
    return campos
  }, [ambiente, ambienteMock, control, errors.ambienteId, errors.formaPagamentoId, getValues, isRetaguarda, isTipoRevenda, pagamentoMock, setValue])

  //--------------------------------

  return (
    <>
      <div
        className={utilClasses.formContainer}
        style={{
          height: '100%',
        }}
      >
        {carregando || props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: !isMobile ? '1rem' : '10px',
            background: '#fff'
          }}
          className={`${classes.formContainer} ${props.loading ? utilClasses.controlLoading : ''
            }`}
        >
          <Grid container spacing={3} style={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Grid item xs={12} md={8} style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2)
            }}>
              <Grid
                container
                spacing={1}
                style={{
                  flex: '1 1 auto',
                  overflowY: 'auto',
                  position: 'relative',
                }}
                className={classes.modulosContainer}
              >
                {!isMobile && <>
                  <Grid item xs={12} md={planosMock.length > 0 ? 6 : 12}>
                    <Controller
                      name="tabelaPrecoId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          conteudo={tabelasPreco.map(
                            (item) =>
                              new KeyValueModel(item.tabelaPrecoId, item.nome),
                          )}
                          fullWidth
                          variant="outlined"
                          label="Tabela de Preços"
                          size='small'
                          {...field}
                          value={getValues('tabelaPrecoId')}
                          onChange={(event) => {
                            if (event) {
                              const item = tabelasPreco.filter(
                                (item) =>
                                  item.tabelaPrecoId === event.target.value,
                              )[0];
                              if (item) {
                                setTabelaPreco(item);
                                setValue('tabelaPrecoId', item.tabelaPrecoId);
                              }
                            }
                          }}
                          helperText={
                            touchedFields.tabelaPrecoId && errors.tabelaPrecoId
                              ? errors.tabelaPrecoId.message
                              : undefined
                          }
                          error={Boolean(
                            errors.tabelaPrecoId && errors.tabelaPrecoId.message,
                          )}
                        />
                      )}
                    />
                  </Grid>
                  {planosMock.length > 0 && <Grid item xs={12} md={6}>
                    <Controller
                      name="planoId"
                      control={control}
                      render={({ field }) => (
                        <SelectSaurus
                          conteudo={
                            [new KeyValueModel(-1, 'Não utilizar plano'), new KeyValueModel(-5, ''), ...planosMock]
                          }
                          fullWidth
                          variant="outlined"
                          label="Plano (opcional)"
                          size='small'
                          {...field}
                          value={planoId}
                          onChange={(event) => {
                            if (event) {
                              const item = planosMock.filter(
                                (item) => item.Key === event.target.value,
                              )[0];
                              if (item) {
                                selecionarPlano(item);
                              } else {
                                removePlano()
                              }
                            }
                          }}
                          helperText={
                            touchedFields.planoId && errors.planoId
                              ? errors.planoId.message
                              : undefined
                          }
                          error={Boolean(
                            errors.planoId && errors.planoId.message,
                          )}
                        />
                      )}
                    />
                  </Grid>}
                </>}
                <Grid item xs={12}>
                  <Typography variant='h6' color='textPrimary'>Módulos</Typography>
                </Grid>
                <Grid item xs={12} className={classes.modulosScroll}>
                  {precosModulosCard.sort((a, b) => a.codigo - b.codigo).map((item, index) => {
                    return (
                      <CardModulosContratoEdicao
                        hasPlanoId={
                          !isEmpty(planoId || planoId !== guidEmpty())
                        }
                        alterarQtd={alterarQtd}
                        model={item}
                        modulos={precosModulosCard}
                        isTipoRevenda={isTipoRevenda}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              {isMobile && <Grid container
                spacing={2} style={{ paddingBottom: theme.spacing(1) }}>
                {props.showFiliais && <Grid item xs={12}>
                  <Button variant='outlined' color='primary' onClick={props.setOpenDialog} fullWidth>
                    <FilialIcon tipo='BUTTON' />
                    Empresas Vinculadas
                  </Button>
                </Grid>}
                <Grid item xs={12} md={6}>
                  <Controller
                    name="tabelaPrecoId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        conteudo={tabelasPreco.map(
                          (item) =>
                            new KeyValueModel(item.tabelaPrecoId, item.nome),
                        )}
                        fullWidth
                        variant="outlined"
                        label="Tabela de Preços"
                        size='small'
                        {...field}
                        value={getValues('tabelaPrecoId')}
                        onChange={(event) => {
                          if (event) {
                            const item = tabelasPreco.filter(
                              (item) =>
                                item.tabelaPrecoId === event.target.value,
                            )[0];
                            if (item) {
                              setTabelaPreco(item);
                              setValue('tabelaPrecoId', item.tabelaPrecoId);
                            }
                          }
                        }}
                        helperText={
                          touchedFields.tabelaPrecoId && errors.tabelaPrecoId
                            ? errors.tabelaPrecoId.message
                            : undefined
                        }
                        error={Boolean(
                          errors.tabelaPrecoId && errors.tabelaPrecoId.message,
                        )}
                      />
                    )}
                  />
                </Grid>
                {planosMock.length > 0 && <Grid item xs={12} md={6}>
                  <Controller
                    name="planoId"
                    control={control}
                    render={({ field }) => (
                      <SelectSaurus
                        conteudo={
                          [new KeyValueModel(-1, 'Não utilizar plano'), new KeyValueModel(-5, ''), ...planosMock]
                        }
                        fullWidth
                        variant="outlined"
                        label="Plano (opcional)"
                        size='small'
                        {...field}
                        value={planoId}
                        onChange={(event) => {
                          if (event) {
                            const item = planosMock.filter(
                              (item) => item.Key === event.target.value,
                            )[0];
                            if (item) {
                              selecionarPlano(item);
                            } else {
                              removePlano()
                            }
                          }
                        }}
                        helperText={
                          touchedFields.planoId && errors.planoId
                            ? errors.planoId.message
                            : undefined
                        }
                        error={Boolean(
                          errors.planoId && errors.planoId.message,
                        )}
                      />
                    )}
                  />
                </Grid>}
              </Grid>}
              <Grid container spacing={2}>
                {camposSuperiores}
                <Grid item xs={12}>
                  <Divider style={{ background: theme.palette.divider }} />
                </Grid>
                <Grid item xs={5}>
                  <Controller
                    name="dataExpiracao"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Data de Expiração"
                        tipo="DATA"
                        manterMascara
                        helperText={
                          touchedFields.dataExpiracao && errors.dataExpiracao
                            ? errors.dataExpiracao.message
                            : undefined
                        }
                        error={Boolean(
                          errors.dataExpiracao && errors.dataExpiracao.message,
                        )}
                        {...field}
                        value={getValues('dataExpiracao') ? getValues('dataExpiracao') : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Box className={classes.greyContainer} style={{ fontSize: 12 }}>
                    Informe a data ao lado caso seja necessário uma data limite de acesso ao sistema. Após a data o bloqueio será realizado.
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ background: theme.palette.divider }} />
                </Grid>
                {/* <Grid item xs={12}>
                  <Box className={classes.greyContainer}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant='caption' color='textSecondary'>Valor Custo Vigente</Typography>
                        <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(model.valorCusto)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='caption' color='textSecondary'>Valor Custo</Typography>
                        <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(getValues('valorCusto'))}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ background: theme.palette.divider }} />
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.greyContainer}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography variant='caption' color='textSecondary'>Valor Vigente</Typography>
                        <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(model.valor)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='caption' color='textSecondary'>Valor Calculado</Typography>
                        <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(getValues('valorCalculado'))}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="valorDesconto"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Desconto"
                        tipo="DECIMAL"
                        manterMascara
                        showStartAdornment
                        helperText={
                          touchedFields.valorDesconto && errors.valorDesconto
                            ? errors.valorDesconto.message
                            : undefined
                        }
                        error={Boolean(
                          errors.valorDesconto && errors.valorDesconto.message,
                        )}
                        {...field}
                        onChange={(ev) => setValores(ev)}
                        onBlur={validateFields}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="valorContrato"
                    control={control}
                    render={({ field }) => (
                      <TextFieldSaurus
                        label="Valor Contrato"
                        {...field}
                        value={getValues('valorContrato')}
                        tipo="DECIMAL"
                        showStartAdornment
                        helperText={
                          errors.valorContrato
                            ? errors.valorContrato.message
                            : undefined
                        }
                        error={Boolean(
                          errors.valorContrato && errors.valorContrato.message,
                        )}
                        manterMascara
                        onChange={(ev) => {
                          setValores(ev)
                        }}
                        onBlur={validateFields}
                      />
                    )}
                  />
                </Grid>
                {(!isEmpty(props.dataCancelamento) && !props.status) && <Grid item xs={12} md={6}>
                  <Typography style={{ fontSize: '0.75rem' }} color='textSecondary'>Data de Cancelamento</Typography>
                  <Typography
                    color="textPrimary"
                    variant="body1"

                    style={{ fontWeight: 500 }}
                  >
                    {toDateString(props.dataCancelamento + 'Z', 'yyyy-MM-DD HH:mm')}
                  </Typography>
                </Grid>} */}
                {(podeVerCusto || !isTipoRevenda) && <>
                  <Grid item xs={6} style={{ borderRight: `2px solid ${theme.palette.primary.main}`, marginTop: props.status ? theme.spacing(3) : 0 }}>
                    <Box display='flex' flexDirection='column' gridGap={theme.spacing(2)} ml={2}>
                      <Typography align='center' color='primary' className={classes.bold}>Em Vigência</Typography>
                      {podeVerCusto && <div>
                        <Typography variant='caption' color='textSecondary'>Valor Custo Vigente</Typography>
                        <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(custoVigente)}</Typography>
                      </div>}
                      {!isTipoRevenda && <>
                        <div>
                          <Typography variant='caption' color='textSecondary'>Valor Tabelado Anterior</Typography>
                          <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(model.valorCalculado)}</Typography>
                        </div>
                        <div>
                          <Typography variant='caption' color='textSecondary'>Valor Vigente</Typography>
                          <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(model.valor)}</Typography>
                        </div>
                        {podeVerCusto && <div>
                          <Typography variant='caption' color='textSecondary'>Lucro Atual</Typography>
                          <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{valorLucro(model.valorContrato, custoVigente)}</Typography>
                        </div>}
                      </>}
                    </Box>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: props.status ? theme.spacing(3) : 0 }}>
                    <Box display='flex' flexDirection='column' gridGap={theme.spacing(2)} ml={2}>
                      <Typography align='center' color='primary' className={classes.bold}>Em Edição</Typography>
                      {podeVerCusto && <div>
                        <Typography variant='caption' color='textSecondary'>Valor Custo</Typography>
                        <div className={classes.valorDescontoBox}>
                          <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(valoresState.valorCusto)}</Typography>
                          {valoresState.valorCusto < valoresState.valorCustoOriginal && <Typography variant='caption'>{toCurrency(valoresState.valorCustoOriginal)}</Typography>}
                        </div>
                      </div>}
                      {!isTipoRevenda && <>
                        <div>
                          <Typography variant='caption' color='textSecondary'>Valor Tabelado</Typography>
                          <Typography style={{ fontSize: '1.1rem', fontWeight: 500 }}>{toCurrency(valoresState.valorCalculado)}</Typography>
                        </div>
                        <div style={{ maxWidth: 150, maxHeight: 47 }}>
                          <Controller
                            name="valorContrato"
                            control={control}
                            render={({ field }) => (
                              <TextFieldSaurus
                                label="Valor do Contrato"
                                showStartAdornment
                                {...field}
                                value={getValues('valorContrato')}
                                inputProps={{
                                  style: {
                                    fontSize: '1.1rem',
                                    fontWeight: 500,
                                  }
                                }}
                                tipo="DECIMAL"
                                helperText={
                                  errors.valorContrato
                                    ? errors.valorContrato.message
                                    : undefined
                                }
                                error={Boolean(
                                  errors.valorContrato,
                                )}
                                manterMascara
                                onChange={(ev) => {
                                  setValores(ev)
                                  validateFields()
                                }}
                              // onBlur={validateFields}
                              />
                            )}
                          />
                        </div>
                        {podeVerCusto && <div>
                          <Typography variant='caption' color='textSecondary'>Novo Lucro</Typography>
                          <Typography style={{ fontSize: '1.1rem', fontWeight: 500, color: '#148EE5' }}>{valorLucro(valoresState.valorContrato, valoresState.valorCusto)}</Typography>
                        </div>}
                      </>}
                    </Box>
                  </Grid>
                </>}
                {!props.status && (
                  <>
                    <Grid item xs={12}>
                      <Divider style={{ background: theme.palette.divider }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant='caption' color='textSecondary'>Data do Cancelamento</Typography>
                      <Typography style={{ fontWeight: 500 }}>{toDateString(new Date(props.dataCancelamento || new Date()), 'DD/MM/yyyy HH:mm')}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant='caption' color='textSecondary'>Motivo do Cancelamento</Typography>
                      <Typography style={{ fontWeight: 500 }}>{MotivoCancelamentoMock.find(item => item.Key === (props.indCancelamento !== undefined ? props.indCancelamento : EnumMotivoCancelamento.Outros))?.Value || 'Outros'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='caption' color='textSecondary'>Detalhes</Typography>
                      <Typography style={{ fontWeight: 500 }}>{props.motivoCancelamento}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});
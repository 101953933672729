import { makeStyles, Theme } from '@material-ui/core';
import { EnumStatusClienteIdeal } from 'model/enums/enum-status-cliente-ideal';

interface StylesProps {
  status: EnumStatusClienteIdeal;
}

export const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  container: {
    borderRadius: '25px',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.45)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    gap: theme.spacing(3),
    position: 'relative'
  },
  roundBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(2),
    background: theme.palette.common.white,
    borderWidth: 4,
    borderStyle: 'solid',
    borderRadius: '50%',
    padding: theme.spacing(2),
    height: 150,
    width: 150,
    borderColor: ({ status }) => {
      switch (status) {
        case EnumStatusClienteIdeal.IDEAL:
          return theme.palette.success.main;
        case EnumStatusClienteIdeal.INTERMEDIARIO:
          return theme.palette.warning.main;
        case EnumStatusClienteIdeal.NAO_IDEAL:
          return theme.palette.error.main;
        default:
          return theme.palette.text.secondary;
      }
    }
  },
  porcentagem: {
    fontSize: '25px',
    color: theme.palette.text.primary,
  },
  pontos: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  semPontuacao: {
    fontSize: '16px',
    color: theme.palette.text.secondary,
    fontWeight: 600
  },
  infoRespostasButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1)
  }
}));
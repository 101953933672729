import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { CardModulosContratoProps } from './card-modulos-contrato-props';
import { DefaultCard } from '../components/default-card';
import { useDefaultCardStyles } from '../components/default-card/default-card-styles';
import { useStyles } from './card-modulos-contrato-styles'
import { BotoesAddESub } from './components/button-add-e-sub/button-add-e-sub';
import { useCallback, useMemo } from 'react';
import { calcPercent } from 'utils/calc-percent';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { InformacaoIcon } from 'views/components/icons';
import { EnumTipoCalculo } from 'model';
import { EnumTipoControleModulo } from 'model/enums/enum-tipo-controle-modulo';
import { useSessaoAtual } from 'services/app';
import { EnumTipoPessoas } from 'model/enums/enum-tipo-pessoas';
import { useThemeQueries } from 'views/theme';
import { toCurrency } from 'utils/toCurrency';
import classNames from 'classnames';

const CardModuloContrato = ({
  model,
  alterarQtd,
  hasPlanoId,
  modulos,
  isTipoRevenda
}: CardModulosContratoProps) => {
  const classes = useDefaultCardStyles();
  const modulosClasses = useStyles();
  const { tipoUsuario } = useSessaoAtual();
  const { isMobile } = useThemeQueries();

  const podeVerCusto = useMemo(() => (tipoUsuario() !== EnumTipoPessoas.FuncionarioFinanceiro && tipoUsuario() !== EnumTipoPessoas.Representante), [tipoUsuario])

  const calcularValor = useCallback(
    (valor: number, quantidade: number, percAdicional: number, custo?: boolean) => {
      if (model.modulo.tipoControle !== EnumTipoControleModulo.Filial) {

        let quantidadeMult = valor;

        if (quantidade === -1) {
          return 0
        }

        if (quantidade > 1) {
          quantidadeMult = (quantidade - 1) * calcPercent(valor, percAdicional);
        }

        if (quantidade === 0) {
          return 0;
        }

        if (quantidade > 1) {
          valor = valor + quantidadeMult
        }
      }

      if (model.modulo.tipoCalculo === EnumTipoCalculo.Modulo && model.modulo.tipoControle === EnumTipoControleModulo.Filial) {
        model.modulo.codModuloBaseCalculo = model.modulo.codModuloBaseCalculo.toString()
        const codsBase = model.modulo.codModuloBaseCalculo.includes(',') ? model.modulo.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : model.modulo.codModuloBaseCalculo.split('-').map(cod => Number(cod))
        const moduloBase = modulos.filter(mod => codsBase.includes(mod.modulo.codigo))

        const valorBase = moduloBase.reduce((prev, curr) => {
          if (curr.quantidadeContratada === 0) return prev
          if (curr.quantidadeContratada === 1) return prev + curr.valor
          return (curr.quantidadeContratada - 1) * calcPercent(curr.valor, curr.percAdicional) + curr.valor + prev
        }, 0)

        const custoBase = moduloBase.reduce((prev, curr) => {
          if (curr.quantidadeContratada === 0) return prev
          if (curr.quantidadeContratada === 1) return prev + curr.valorCusto
          return (curr.quantidadeContratada - 1) * calcPercent(curr.valorCusto, curr.percAdicionalCusto) + curr.valorCusto + prev
        }, 0)



        valor = moduloBase ? ((model.quantidadeContratada) * calcPercent(custo ? custoBase : valorBase, percAdicional)) : valor
      } else if (model.modulo.tipoCalculo === EnumTipoCalculo.Modulo) {
        const codBase = Number(model.modulo.codModuloBaseCalculo)
        const moduloBase = modulos.find(mod => mod.modulo.codigo === codBase)

        valor = moduloBase ? valor * (moduloBase?.quantidadeContratada || 1) : valor
      }

      return valor
    },
    [model, modulos],
  );

  //Isso é uma validação para exibir ou não 4 casas decimais no card
  const retornaNumeroDeCasas = useCallback((value: number) => {
    const casaDecimal = toDecimalString(value, 4).split(',')[1][2]

    return Number(casaDecimal) > 0 ? 4 : 2
  }, [])

  const custoTotal = useMemo(() => calcularValor(model.valorCusto, model.quantidadeContratada, model.percAdicionalCusto, true),
    [calcularValor, model.percAdicionalCusto, model.quantidadeContratada, model.valorCusto])

  const valorTotal = useMemo(() => calcularValor(model.valor, model.quantidadeContratada, model.percAdicional),
    [calcularValor, model.percAdicional, model.quantidadeContratada, model.valor])

  const valorCustoTotalOriginal = useMemo(() => model.valor === model.valorCustoOriginal ? valorTotal : calcularValor(model.valorCustoOriginal, model.quantidadeContratada, model.percAdicional),
    [calcularValor, model.percAdicional, model.quantidadeContratada, model.valor, model.valorCustoOriginal, valorTotal])

  // const disableButton = useMemo(() => {
  //   if (model.modulo.tipoCalculo !== EnumTipoCalculo.Modulo || model.modulo.tipoControle === EnumTipoControleModulo.Filial) {
  //     return false
  //   }

  //   const moduloVinculado = modulos.find(mod => mod.modulo.codigo.toString() === model.modulo.codModuloBaseCalculo)

  //   if (!moduloVinculado || moduloVinculado.quantidadeContratada < 1) {
  //     return true
  //   }

  //   return false
  // }, [model.modulo.codModuloBaseCalculo, model.modulo.tipoCalculo, model.modulo.tipoControle, modulos])

  return (
    <Grid item xs={12}>
      <DefaultCard>
        <div className={`${classes.cardContent} ${modulosClasses.cardModulosContainer}`}>
          <Grid container spacing={1} alignItems='center' justifyContent={isTipoRevenda ? 'space-between' : undefined}>
            <Grid item lg={podeVerCusto ? 3 : 6} xs={12} className={modulosClasses.tituloModulo}>
              <Box display='block' width='100%'>
                <Typography color='textSecondary' variant='caption'>
                  Nome:
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  style={{ fontWeight: 600 }}
                >
                  {model.modulo.nome}
                </Typography>
              </Box>
            </Grid>
            {podeVerCusto && (
              <Grid item lg={2} md={3} xs={6} className={modulosClasses.flexEnd}>
                {model.modulo.tipoControle !== EnumTipoControleModulo.Filial ? (
                  <>
                    <div className={classNames([modulosClasses.custoTotal, modulosClasses.valorContainer])}>
                      <Typography variant="caption">
                        Custo Unitário
                      </Typography>
                      {model.valorCusto < model.valorCustoOriginal && (
                        <Typography variant='caption' className={modulosClasses.campoDesconto}>
                          {toCurrency(model.valorCustoOriginal)}
                        </Typography>
                      )}
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        style={{ fontWeight: 600 }}
                      >
                        {model.valorCusto ? `R$ ${toDecimalString(model.valorCusto, retornaNumeroDeCasas(model.valorCusto))}` :
                          `R$ ${toDecimalString(0)}`}
                      </Typography>
                    </div>
                    {(model.percAdicionalCusto !== 100 && model.percAdicionalCusto !== 0) &&
                      <Tooltip
                        title={`Desconto para mais quantidades: ${model.percAdicionalCusto ? (100 - model.percAdicionalCusto) : 0}%`}
                        placement="right"
                      >
                        <Box height='100%' display='flex' alignItems='flex-end'>
                          <InformacaoIcon
                            tipo="BUTTON"
                            style={{
                              width: 18,
                              marginLeft: 8,
                            }}
                          />
                        </Box>
                      </Tooltip>}
                  </>
                ) : (
                  <>
                    <div className={modulosClasses.percAdicional}>
                      <Typography variant="caption">
                        Percentual do Custo
                      </Typography>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        style={{ fontWeight: 600 }}
                      >
                        {toDecimal(model.percAdicionalCusto)}%
                      </Typography>
                    </div>
                  </>
                )}
              </Grid>
            )}
            {podeVerCusto && (
              <Grid item lg={2} md={2} xs={6} className={modulosClasses.center}>
                <div className={classNames([modulosClasses.custoTotal, modulosClasses.valorContainer])}>
                  <Typography variant="caption">
                    Custo Total
                  </Typography>
                  {valorCustoTotalOriginal > custoTotal && (
                    <Typography variant='caption' className={modulosClasses.campoDesconto}>
                      {toCurrency(valorCustoTotalOriginal)}
                    </Typography>
                  )}
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    style={{ fontWeight: 600 }}
                  >
                    {model.valorCusto || model.modulo.tipoControle === EnumTipoControleModulo.Filial ? `R$ ${toDecimalString(custoTotal, retornaNumeroDeCasas(custoTotal))}` :
                      `R$ ${toDecimalString(0)}`}
                  </Typography>
                </div>
              </Grid>
            )}
            {!isTipoRevenda && <Grid item lg={2} md={3} xs={6} className={modulosClasses.center}>
              {model.modulo.tipoControle !== EnumTipoControleModulo.Filial ?
                <>
                  <div>
                    <Typography variant="caption">Valor Unitário</Typography>
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      style={{ fontWeight: 600 }}
                    >
                      R$ {toDecimalString(model.valor, retornaNumeroDeCasas(model.valor))}
                    </Typography>
                  </div>
                  {(model.percAdicional !== 100 || model.valor !== model.valorCusto) && <div>
                    <Tooltip
                      title={<Box display='flex' flexDirection='column'>
                        {model.percAdicional !== 100 && <div>Desconto para mais quantidades: ${model.percAdicional ? (100 - model.percAdicional) : 0}%</div>}
                        {model.valor !== model.valorCustoOriginal && <div>Desconto de {toCurrency(model.valorCustoOriginal - model.valor)} no Módulo.</div>}
                      </Box>}
                      placement="right"
                    >
                      <div>
                        <InformacaoIcon
                          tipo="BUTTON"
                          style={{
                            width: 18,
                            marginLeft: 8,
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>}
                </> : (
                  <>
                    <div className={modulosClasses.percAdicional}>
                      <Typography variant="caption">Perc. Adicional</Typography>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        style={{ fontWeight: 600 }}
                      >
                        {toDecimal(model.percAdicional)}%
                      </Typography>
                    </div>
                    <div>
                      <Tooltip
                        title={<div>
                          <Typography>Este módulo é cobrado de acordo com o valor dos seguintes módulos considerando o percentual adicional.</Typography>
                          <br />
                          {modulos.filter(m => {
                            const modulos = model.modulo.codModuloBaseCalculo.includes(',') ? model.modulo.codModuloBaseCalculo.split(',').map(cod => Number(cod)) : model.modulo.codModuloBaseCalculo.split('-').map(cod => Number(cod))
                            return modulos.includes(m.modulo.codigo)
                          }).map(m => (
                            <Typography>- {m.modulo.nome};</Typography>
                          ))}
                        </div>}
                        placement="right"
                      >
                        <div>
                          <InformacaoIcon
                            tipo="BUTTON"
                            style={{
                              width: 18,
                              marginLeft: 8,
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                )}
            </Grid>}
            {!isTipoRevenda && <Grid item lg={podeVerCusto ? 2 : 3} md={2} xs={6} className={modulosClasses.valorTotal}>
              <Typography variant="caption">
                Valor Total:
              </Typography>
              <Typography
                color="textPrimary"
                variant="body1"
                style={{ fontWeight: 600 }}
              >
                <b> R$ {toDecimalString(valorTotal, retornaNumeroDeCasas(valorTotal))}</b>
              </Typography>
            </Grid>}
            {isMobile && <Grid item xs={6}>

            </Grid>}
            <Grid item xs={6} lg={1} md={2} className={`${modulosClasses.botaoQtd}`}>
              <BotoesAddESub
                model={model}
                alterarQtd={alterarQtd}
                hasPlanoId={hasPlanoId}
                disable={false}
              />

              {model.modulo.quantidadeMaxima > 1 && (
                <Tooltip title={
                  <Box display='flex' flexDirection='column'>
                    <div>
                      <Typography variant="caption" style={{
                        marginRight: 4
                      }}>
                        Quantidade Mínima:
                      </Typography>
                      <Typography variant="caption">
                        {model.modulo.quantidadeMinima === -1 ? 0 : model.modulo.quantidadeMinima}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="caption" style={{
                        marginRight: 4
                      }}>
                        Quantidade Máxima:
                      </Typography>
                      <Typography variant="caption">
                        {model.modulo.quantidadeMaxima === -1 ? 999999999 : model.modulo.quantidadeMaxima}
                      </Typography>
                    </div>
                  </Box>
                } className={modulosClasses.infoQtd}>
                  <div>
                    <InformacaoIcon tipo='BUTTON' />
                  </div>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </div>
      </DefaultCard>
    </Grid >
  );
};

export default CardModuloContrato

import { useCallback, useState, useEffect, useRef } from 'react';
import { useStyles } from './input-color-styles';
import { ColorPicker } from 'material-ui-color';
import { FormHelperText } from '@material-ui/core';

export interface InputColorProps {
    labelTxt: string,
    onChange(cor: string): void,
    valor: string,
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

export const InputColor = (props: InputColorProps) => {
    const classes = useStyles();
    const [cor, setCor] = useState(props.valor);
    const timeoutSelect = useRef<any>();
    const containerRef = useRef<any>();

    const onChangeColor = useCallback((color: any) => {
        setCor(color);
        clearTimeout(timeoutSelect.current);
        timeoutSelect.current = setTimeout(() => {
            props.onChange('#' + color.hex);
        }, 500);
    }, [props]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.children[1].children[0].setAttribute('tabIndex', '-1');
        }
    }, []);

    useEffect(() => {
        setCor(props.valor);
    }, [props.valor]);

    return (
        <div className={classes.inputContainer} ref={containerRef} >
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled" data-shrink="true">{props.labelTxt}</label>
            <ColorPicker
                defaultValue="#FFFFFF"
                disablePlainColor
                onChange={onChangeColor}
                value={cor}
            />
            {props.helperText && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
        </div>
    );
};

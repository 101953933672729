
import { useMemo } from 'react';
import * as Yup from 'yup';

export const useFormCancelarContratoValidation = () => {


    const FormCancelarContratoValidationYup = useMemo(() => {
        return Yup.object().shape({
            motivoCancelamento: Yup.string().required('Informações obrigatórias').typeError('Informações obrigatórias'),
        });
    }, [])

    return {
        FormCancelarContratoValidationYup,
    }
}
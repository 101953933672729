import { Button, Grid } from '@material-ui/core';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { TextFieldSaurus } from 'views/components/controles/inputs';
import { CircularLoading, makeUtilClasses } from 'views';
import {
  DefaultFormRefs,
  DefaultFormProps,
} from '../../utils/form-default-props';
import { picker } from 'utils/picker';
import { Controller, useForm } from 'react-hook-form';
import { ContratoCancelamentoModel } from 'model/app/forms/contrato-cancelamento/contrato-cancelamento-model';
import { useFormCancelarContratoValidation } from './form-cancelar-contrato-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectSaurus } from 'views/components/controles/selects/select-saurus/select-saurus';
import { MotivoCancelamentoMock } from 'data/mocks/motivo-cancelamento-mock';

export const FormCancelarContrato = forwardRef<
  DefaultFormRefs<ContratoCancelamentoModel>,
  DefaultFormProps<ContratoCancelamentoModel>
>(({ loading, ...props }: DefaultFormProps<ContratoCancelamentoModel>, ref) => {
  const utilClasses = makeUtilClasses();
  const [modelForm, setModelForm] = useState<ContratoCancelamentoModel>(
    new ContratoCancelamentoModel(),
  );
  const { FormCancelarContratoValidationYup } = useFormCancelarContratoValidation();

  const {
    handleSubmit,
    control,
    formState: { errors, touchedFields },
    reset,
  } = useForm<ContratoCancelamentoModel>({
    defaultValues: { ...modelForm },
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(FormCancelarContratoValidationYup)
  });

  const onSubmit = async (values: ContratoCancelamentoModel) => {
    const model = picker<ContratoCancelamentoModel>(values, new ContratoCancelamentoModel());
    props.onSubmit(model, modelForm);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
    resetForm: () => {
    },
    fillForm: (model: ContratoCancelamentoModel) => {
      setModelForm(model);
      reset({ ...model });
    },
  }));

  return (
    <>
      <div className={utilClasses.formContainer}>
        {loading && props.showLoading ? (
          <CircularLoading tipo="FULLSIZED" />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={loading ? utilClasses.controlLoading : ''}
        >
          <Grid container spacing={2} style={{ maxWidth: '100%' }}>
            <Grid item xs={12}>
              <Controller
                name="indCancelamento"
                control={control}
                render={({ field }) => (
                  <SelectSaurus
                    fullWidth
                    allowSubmit={true}
                    disabled={loading}
                    conteudo={MotivoCancelamentoMock}
                    label="Motivo do Cancelamento"
                    variant="outlined"
                    error={Boolean(errors.indCancelamento && errors.indCancelamento.message)}
                    helperText={
                      touchedFields.indCancelamento || errors.indCancelamento
                        ? errors.indCancelamento?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="motivoCancelamento"
                control={control}
                render={({ field }) => (
                  <TextFieldSaurus
                    tipo="TEXTO"
                    fullWidth
                    allowSubmit={true}
                    disabled={loading}
                    label="Mais Informações"
                    multiline
                    minRows={3}
                    variant="outlined"
                    error={Boolean(errors.motivoCancelamento && errors.motivoCancelamento.message)}
                    helperText={
                      touchedFields.motivoCancelamento || errors.motivoCancelamento
                        ? errors.motivoCancelamento?.message
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button style={{ display: 'none' }} type="submit"></Button>
        </form>
      </div>
    </>
  );
});

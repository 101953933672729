import { Box, FormControlLabel, RadioGroup, Typography } from "@material-ui/core";
import { useGetClienteIdeal } from "data/api/gestao/cliente-ideal/get-cliente-ideal";
import { isEmpty } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import { useSessaoAtual, useToastSaurus } from "services/app";
import { DefaultFormProps, DefaultFormRefs } from "views/components/form/utils";
import { useStyles } from "./form-pergunta-styles";
import { guidEmpty } from "utils/guid-empty";
import { CircularLoading } from "views/components/utils";
import { PessoaClienteIdealPutModel } from "model/api/gestao/cliente-ideal/pessoa-cliente-ideal-put-model";
import { ClienteIdealPerguntaModel } from "model/api/gestao/cliente-ideal/cliente-ideal-pergunta-model";
import { RadioSaurus } from "views/components/controles/radio/saurus-radio";
import { podeResponderPerguntasIndicacao, podeResponderPerguntasInternas, podeVerPerguntasInternas } from "views/components/cliente-ideal/data/clienteIdealAccessData";

interface FormPerguntaProps extends DefaultFormProps<PessoaClienteIdealPutModel[]> {
    perguntas: ClienteIdealPerguntaModel[];
    alterarBotoes: (perguntasLength: number, index: number) => void;
    close?: (msg?: string) => void;
}

export interface FormPerguntaRefs extends DefaultFormRefs<PessoaClienteIdealPutModel[]> {
    avancarPergunta: () => void;
    voltarPergunta: () => void;
    isPrimeiraPergunta: () => boolean;
    isUltimaPergunta: () => boolean;
}

export const FormPerguntas = forwardRef<
    FormPerguntaRefs,
    FormPerguntaProps
>((props: FormPerguntaProps, ref) => {

    const { tipoUsuario } = useSessaoAtual()
    const { showToast } = useToastSaurus();
    const { getClienteIdeal, carregando } = useGetClienteIdeal();
    const classes = useStyles();

    const [perguntas, setPerguntas] = useState<ClienteIdealPerguntaModel[]>(props.perguntas)
    const [perguntaAtual, setPerguntaAtual] = useState<number>(0)
    const [, setUpdate] = useState<boolean>(false)

    const [currentPerguntaWithIndex, setCurrentPerguntaWithIndex] = useState<{
        currPergunta: PessoaClienteIdealPutModel,
        index: number
    }>()


    const {
        handleSubmit,
        reset,
        getValues,
        setValue
    } = useForm<PessoaClienteIdealPutModel[]>({
        defaultValues: [],
        criteriaMode: 'all',
        mode: 'all',
    });

    const onSubmit = (model: PessoaClienteIdealPutModel[]) => {
        props.onSubmit(model)
    }

    const getPerguntas = async (values: PessoaClienteIdealPutModel[]) => {
        try {
            const res = await getClienteIdeal();

            if (res.erro) throw res.erro

            if (!res.resultado) return

            const sortArr = (perguntas: ClienteIdealPerguntaModel[]) => {
                let arrOrdenado = [...perguntas];
                for (let i = 0; i < perguntas.length; i++) {
                    let newIndex = i;
                    let perguntaDependente: ClienteIdealPerguntaModel | undefined;
                    perguntas[i].respostas.forEach(resp => {
                        if (!isEmpty(resp.perguntaIdDependente)) {
                            let pd = arrOrdenado.find(x => x.id === resp.perguntaIdDependente);
                            if (pd) {
                                const indexPergunta = arrOrdenado.indexOf(pd);
                                if (indexPergunta >= newIndex) {
                                    newIndex = indexPergunta;
                                    perguntaDependente = { ...pd }
                                }
                            }
                        }
                    })

                    if (perguntaDependente && newIndex > i) {
                        arrOrdenado[newIndex] = { ...perguntas[i] };
                        arrOrdenado[i] = { ...perguntaDependente };
                    }
                }

                return arrOrdenado;
            }





            const currentUserType = tipoUsuario()

            // Filtra perguntas que serão ou não mostradas na tela
            let internas = res.resultado.data.perguntas.filter(pergunta => pergunta.interna && pergunta.ativo);
            let externas = res.resultado.data.perguntas.filter(pergunta => !pergunta.interna && pergunta.ativo);


            let filteredRes = [...externas];

            if (podeVerPerguntasInternas.includes(currentUserType)) {
                filteredRes = [...filteredRes, ...internas]
            }

            values = values.filter(val => {
                const found = filteredRes.some(x => x.id === val.perguntaId)
                return found;
            })

            const data = sortArr(filteredRes)

            if (isEmpty(data) && props.close) {
                props.close('Não há perguntas cadastradas no sistema.');
                return
            }
            if (isEmpty(values)) {
                reset(data.map(item => new PessoaClienteIdealPutModel(
                    guidEmpty(),
                    item.id,
                    ''
                )))
            } else {
                if (values.length < data.length) {
                    values = data.map((item, i) => {
                        const resposta = values.find(x => x.perguntaId === item.id)
                        if (!resposta) {
                            return {
                                perguntaId: item.id,
                                pessoaId: '',
                                respostaId: '',
                            }
                        }
                        return {
                            ...resposta
                        }
                    })
                }
                
                const sortedValues = [...values].filter(x => {
                    const pergunta = data.find(perg => perg.id === x.perguntaId)
                    return !pergunta ? true : pergunta.ativo
                }).sort((a, b) => {
                    let indexA = data.findIndex(item => item.id === a.perguntaId);
                    let indexB = data.findIndex(item => item.id === b.perguntaId);

                    return indexA - indexB;
                })
                reset(sortedValues)
            }
            setPerguntas(data);
            props.alterarBotoes(data.length, 0)

        } catch (e: any) {
            showToast('error', e.message)
            if (props.close) {
                props.close()
            }
        }
    }

    const retornarRespostas = (perguntaIndex: number) => {
        let respostas = perguntas[perguntaIndex].respostas.filter(resposta => resposta.ativo);

        respostas = respostas.filter(resposta => {
            if (resposta.perguntaIdDependente) {
                const perguntaDependente = perguntas.find(pergunta => pergunta.id === resposta.perguntaIdDependente);

                if (resposta.respostaIdDependente && perguntaDependente) {
                    const indexPerguntaDependente = perguntas.indexOf(perguntaDependente)
                    const respostaDaPerguntaDependente = getValues(`${indexPerguntaDependente}.respostaId`);

                    return respostaDaPerguntaDependente === resposta.respostaIdDependente;
                }
            }

            return true
        })

        return respostas;
    }

    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onSubmit)()
        },
        fillForm(values: PessoaClienteIdealPutModel[]) {
            if (isEmpty(props.perguntas)) {
                getPerguntas(values);
                return
            }
            props.alterarBotoes(values.length, 0)
            reset(values)
        },
        resetForm() {

        },
        avancarPergunta() {
            if (!currentPerguntaWithIndex)
                return
            if (!isEmpty(getValues(`${currentPerguntaWithIndex?.index}.respostaId`))) {
                const proximaRespostaId = perguntas[perguntaAtual + 1].id;
                let proximaPergunta: PessoaClienteIdealPutModel;
                let index = 0;
                for(let i = 0;i<=perguntas.length;i++){
                    
                    if(proximaRespostaId === getValues(`${i}.perguntaId`)){
                        proximaPergunta = getValues(`${i}`)
                        index = i;
                        break;
                    }
                }
                if (!retornarRespostas(perguntaAtual + 1).some(x => x.id === proximaPergunta.respostaId)) {
                    setValue(`${index}.respostaId`, '')
                }
                if (isEmpty(retornarRespostas(perguntaAtual + 1))) {
                    setValue(`${index}.respostaId`, '')
                    if (perguntaAtual + 2 > perguntas.length - 1) {
                        handleSubmit(onSubmit)()
                        return
                    }
                    setPerguntaAtual(prev => {
                        props.alterarBotoes(perguntas.length, prev + 2)
                        return prev + 2
                    })
                    return
                }
                setPerguntaAtual(prev => {
                    props.alterarBotoes(perguntas.length, prev + 1)
                    return prev + 1
                })
                return
            } else {
                if (perguntas[perguntaAtual].interna && !podeResponderPerguntasInternas.includes(tipoUsuario())) {
                    setPerguntaAtual(prev => {
                        props.alterarBotoes(perguntas.length, prev + 1)
                        return prev + 1
                    })
                } else if (!perguntas[perguntaAtual].interna && !podeResponderPerguntasIndicacao.includes(tipoUsuario())) {
                    setPerguntaAtual(prev => {
                        props.alterarBotoes(perguntas.length, prev + 1)
                        return prev + 1
                    })
                } else {
                    showToast('info', 'Marque uma das respostas.')
                }
            }
        },
        voltarPergunta() {
            if (perguntaAtual >= 1) {
                if (isEmpty(retornarRespostas(perguntaAtual - 1))) {
                    if (perguntaAtual - 2 < 0) {
                        props.close && props.close()
                        return
                    }

                    setPerguntaAtual(prev => {
                        props.alterarBotoes(perguntas.length, prev - 2)
                        return prev - 2
                    })
                    return
                }
                setPerguntaAtual(prev => {
                    props.alterarBotoes(perguntas.length, prev - 1)
                    return prev - 1
                })
            }
        },
        isPrimeiraPergunta: () => perguntaAtual === 0,
        isUltimaPergunta: () => perguntaAtual === perguntas.length - 1
    }))

    const changeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (currentPerguntaWithIndex) {
            setValue(`${currentPerguntaWithIndex?.index}.respostaId`, (event.target as HTMLInputElement).value);
            setUpdate(prev => !prev)
        }
    }


    useEffect(() => {
        const idPergunta = perguntas[perguntaAtual]?.id;
        if (idPergunta) {
            for (let i = 0; i <= perguntas.length; i++) {
                const id = getValues(`${i}.perguntaId`);
                if (id === idPergunta) {
                    setCurrentPerguntaWithIndex({
                        currPergunta: getValues(`${i}`),
                        index: i
                    })
                    break;
                }
            }
        }
    }, [perguntaAtual, perguntas, getValues])

    return <Box className={classes.container}>
        {carregando && <CircularLoading tipo='FULLSIZED' />}
        {perguntas.length > 0 && <>
            <Typography align='center'>Pergunta {perguntaAtual + 1} de {perguntas.length}</Typography>
            <Typography align='center' variant='h5' color='primary'>{perguntas[perguntaAtual].pergunta}</Typography>
            <Typography variant='body1' color='textPrimary'>{perguntas[perguntaAtual].detalhe}</Typography>
            <RadioGroup onChange={changeRadio} value={currentPerguntaWithIndex?.currPergunta?.respostaId} defaultValue={''}>
                {retornarRespostas(perguntaAtual).map((resposta, index) => {
                    let disabled = false
                    if (perguntas[perguntaAtual].interna) {
                        if (!podeResponderPerguntasInternas.includes(tipoUsuario())) {
                            disabled = true
                        }
                    } else {
                        if (!podeResponderPerguntasIndicacao.includes(tipoUsuario())) {
                            disabled = true
                        }
                    }
                    console.log(perguntaAtual)
                    return (
                        <FormControlLabel
                            key={index}
                            checked={resposta.id === currentPerguntaWithIndex?.currPergunta?.respostaId}
                            value={resposta.id}
                            control={<RadioSaurus />}
                            label={resposta.resposta}
                            disabled={disabled}
                        />
                    )
                })}
            </RadioGroup>
        </>}
    </Box>
})